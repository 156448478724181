import React from "react";
import logo from "../assets/logo.svg";
import logoText from "../assets/logoText.svg";

export const Header = () => {
  return (
    <div className="header">
      <img src={logo} alt="logoBox" className="logoBox" />
      <img src={logoText} alt="logoBox" className="logoText" />
    </div>
  );
};
