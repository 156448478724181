import React from "react";
import aboutVector from "../assets/aboutTxt.svg";
import aboutTxtCircle from "../assets/aboutTxtCircle.svg";
import stars from "../assets/stars.svg";
import star from "../assets/star.svg";

export const About = () => {
  return (
    <div className="aboutTextBlock">
      <img src={aboutVector} alt="aboutVector" className="aboutVector" />
      <img
        src={aboutTxtCircle}
        alt="aboutTxtCircle"
        className="aboutTxtCircle"
      />
      <img src={stars} alt="stars" className="stars" />
      <img src={star} alt="star" className="star" />
      <span className="aboutTxtStyle">
        Наша задача - обеспечить безопасность, прозрачность и правильную
        организацию бизнеса наших клиентов. Кроме работы с предпринимателями, мы
        проводим частные консультации: вместе определяем цель и выстраиваем план
        действий в зависимости из вашего текущего финансового положения. Наши
        проекты направлены на обучение населения финансовой грамотности и
        формированию кредитных и инвестиционных портфелей.
      </span>
    </div>
  );
};
