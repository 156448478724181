import React from "react";
import iphone from "../assets/iphone.svg";
import mainTxt from "../assets/mainTxt.svg";
import binoculars from "../assets/binoculars.svg";
import purpleVectorLine from "../assets/purpleVectorLine.svg";
import sketch1 from "../assets/sketch1.svg";
import sketch2 from "../assets/sketch2.svg";
import iphone14 from "../assets/iphone14.svg";
import bookmarkTxt from "../assets/bookmarkTxt.svg";
import browser from "../assets/browser.svg";
import purpleVector2 from "../assets/purpleVector2.svg";
import sketch3 from "../assets/sketch3.svg";
import sketch4 from "../assets/sketch4.svg";
import iphone15 from "../assets/iphone15.svg";
import spam from "../assets/spam.svg";
import mail from "../assets/mail.svg";

export const TwoColumns = () => {
  return (
    <div className="twoColumns">
      <div className="column1">
        <div className="block1">
          <img src={mainTxt} alt="mainTxt" className="mainTxt" />
          <p className="textMainAbout">
            Будь в курсе все финансовых новостей в удобном формате
          </p>
          <img src={binoculars} alt="binoculars" className="binoculars" />
          <img src={sketch1} alt="sketch1" className="sketch1" />
          <img src={sketch2} alt="sketch2" className="sketch2" />
        </div>
        <img
          src={purpleVectorLine}
          alt="purpleVectorLine"
          className="purpleVectorLine"
        />
        <img src={iphone14} alt="iphone14" className="iphone14" />
        <div className="block3">
          <img src={spam} alt="spam" className="spam" />
          <p className="textColor">
            Получай подборку лучших статей специально для тебя и улучшай свою
            финансовую грамотность.
          </p>
          <img src={mail} alt="mail" className="mail" />
        </div>
      </div>
      <div className="column2">
        <img src={iphone} alt="iphone" className="iphone" />
        <div className="block2">
          <img src={bookmarkTxt} alt="bookmarkTxt" className="bookmarkTxt" />
          <p className="blockTextAbout">
            Сохраняй понравившиеся статьи по категория и они всегда будут в
            удобном доступе.
          </p>
          <img src={browser} alt="browser" className="browser" />
          <img
            src={purpleVector2}
            alt="purpleVector2"
            className="purpleVector2"
          />
          <img src={sketch3} alt="sketch3" className="sketch3" />
          <img src={sketch4} alt="sketch4" className="sketch4" />
          <img src={iphone15} alt="iphone15" className="iphone15" />
        </div>
      </div>
    </div>
  );
};
