import React from "react";
import journalText from "../assets/journaltxt.svg";
import green from "../assets/green.svg";
import tokyoBrowser from "../assets/tokyBrowser.svg";
import smiles from "../assets/smiles.svg";
import arrowDown from "../assets/arrowDown.svg";
import lightning from "../assets/lightning.png";

export const TopBlock = () => {
  return (
    <div className="topBlock">
      <img src={tokyoBrowser} alt="tokyoBrowser" className="tokyoBrowser" />
      <img src={lightning} alt="lightningVector" className="lightningVector" />
      <img src={journalText} alt="journalText" className="journalText" />
      <img src={green} alt="greenVector" className="greenVector" />
      <img src={smiles} alt="smilesVector" className="smilesVector" />
      <span className="topBlockText">
        Будь в курсе всех финансовых событий и улучшай свою финансовую
        грамотность вместе с нами. Читай статьи в удобном формате там где удобно
        тебе.
      </span>
      <img src={arrowDown} alt="arrowDown" className="arrowDown" />
    </div>
  );
};
