import React from "react";
import categories from "../assets/categories.svg";
import slide1 from "../assets/slide1.svg";
import slide2 from "../assets/slide2.svg";
import slide3 from "../assets/slide3.svg";
import slide4 from "../assets/slide4.svg";
import slide5 from "../assets/slide5.svg";
import categoriesSketch from "../assets/categoriesSketch.svg";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

export const Categories = () => {
  return (
    <div className="categoriesBlock">
      <img
        src={categoriesSketch}
        alt="categoriesSketch"
        className="categoriesSketch"
      />
      <img src={categories} alt="categories" className="categories" />
      <Swiper
        resistanceRatio={5}
        watchOverflow={false}
        centeredSlidesBounds={false}
        threshold={50}
        centeredSlides={true}
        mousewheel={true}
        autoplay={true}
        style={{
          width: 770,
          alignItems: "center",
          alignContent: "center",
          marginTop: 36,
          paddingLeft: 110,
        }}
        spaceBetween={-150}
        slidesPerView={2}
      >
        <SwiperSlide>
          <img src={slide1} alt="slide1" className="slide1" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide2} alt="slide2" className="slide2" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide3} alt="slide3" className="slide3" />
        </SwiperSlide>
        <SwiperSlide>
          <img src={slide4} alt="slide4" className="slide4" />
        </SwiperSlide>
        <SwiperSlide>
          <img
            style={{ paddingRight: 500 }}
            src={slide5}
            alt="slide5"
            className="slide5"
          />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};
