import React from "react";
import {Header} from "../../components/Header";
import {TopBlock} from "../../components/TopBlock";
import {About} from "../../components/About";
import {Categories} from "../../components/Categories";
import {TwoColumns} from "../../components/TwoColumns";

export const Home = () => {
    return (
        <div className="app">
            <div className="main">
                <Header />
                <TopBlock />
                <About />
                <Categories />
                <TwoColumns/>
                <div className="gradientGreen" />
                <div className="ellipse" />
                <div className="greenEllipse" />
                <div className="greenGradient" />
            </div>
            <footer className="footerStyle">
                <h1 className="emailTxt">silakapital@gmail.com</h1>
                <h1 className="responseTxt">Для предложений и обращений</h1>
                <h1 className="companyTxt">
                    ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ "Сила Капитал" 156003,
                    Костромская область, Г. КОСТРОМА, УЛ КОММУНАРОВ, Д. 40, ЭТАЖ 3,
                    ПОМЕЩ./К. 2/50
                </h1>
                <div className="copyrightRow">
                    <div className="line" />
                    <span className="bottomCopyright">© Copyright Сила Капитал 2022</span>
                    <div className="line" />
                </div>
            </footer>
        </div>
    );
}