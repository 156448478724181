import React from "react";
import "./Personal.css";

export const Personal = () => {
    return (
        <main className="personal">
            <h1
                style={{
                    paddingTop: "3pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left"
                }}
            >
                <a name="bookmark0">Согласие на обработку персональных данных</a>
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
                Я подтверждаю, что все персональные данные, предоставленные мною в ООО
            </p>
            <p
                style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left"
                }}
            >
                «Сила Капитал», являются достоверными и полными.
            </p>
            <p
                style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                В соответствии с Федеральным законом №152-ФЗ от 27.07.2006 г. «О
                персональных данных» я, настоящим, даю согласие ООО «Сила Капитал» (адрес:
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                156003, Россия, Костромская область, г. Кострома, ул. Коммунаров, д. 40,
                пом. 2/50 (далее – ООО «Сила Капитал»)) на обработку информации, относящейся
                к моим персональным данным, а именно:ФИО, номера телефонов, e- mail, а также
                любой иной информации, предоставленной в ООО «Сила Капитал» путем заполнения
                и направления мною анкет, интернет-заявок, регистрации в
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "14pt",
                    textAlign: "left"
                }}
            >
                мобильном приложении <span className="h2">ЖУРНАЛ ПЛЮС</span>
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                Обработка персональных данных может осуществляться как с использованием
                средств автоматизации (включая программное обеспечение), так и без
                использования таковых (с использованием различных материальных носителей,
                включая бумажные носители), включая сбор, запись, систематизацию,
                накопление, хранение, уточнение (обновление, изменение), извлечение,
                использование, передачу (распространение, предоставление, доступ),
                обезличивание, блокирование, удаление, уничтожение.
            </p>
            <h1
                style={{
                    paddingTop: "7pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "15pt",
                    textAlign: "left"
                }}
            >
                <a name="bookmark1">Целями обработки персональных данных является:</a>
            </h1>
            <ul id="l1">
                <li data-list-text="">
                    <p
                        className="s1"
                        style={{
                            paddingLeft: "23pt",
                            textIndent: "-18pt",
                            lineHeight: "14pt",
                            textAlign: "left"
                        }}
                    >
                        проверка достоверности указанных мною сведений;
                    </p>
                </li>
                <li data-list-text="">
                    <p
                        className="s1"
                        style={{ paddingLeft: "23pt", textIndent: "-18pt", textAlign: "left" }}
                    >
                        информирования меня о новых продуктах и услугах ООО «Сила Капитал»,
                        совместныхпродуктов ООО «Сила Капитал» и третьих лиц, в интересах
                        которых действует ООО
                    </p>
                </li>
            </ul>
            <p
                className="s1"
                style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}
            >
                «Сила Капитал», продуктов (товаров, работ, услуг) третьих лиц, в интересах
                которых действуетООО «Сила Капитал», путем осуществления прямых контактов со
                мной с помощью технических средств связи (включая электронные средства
                связи, почтовые отправления, SMS- сообщения, push-уведомления);
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                Устанавливая и используя сайты, мобильные приложения и другие технические
                средства, разработанные и принадлежащие ООО «Сила Капитал», я даю ООО
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                «Сила Капитал» согласие на сбор, обработку и хранение истории посещаемых
                интернет-сайтов и страниц с целью предоставления мне персонализированных
                маркетинговых предложений от ООО «Сила Капитал» и партнеров ООО «Сила
                Капитал».
            </p>
            <h1
                style={{
                    paddingTop: "7pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left"
                }}
            >
                <a name="bookmark2">Согласие на обработку сведений об абоненте</a>
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
                Настоящим поручаю ООО «Сила Капитал», при необходимости по решению ООО
            </p>
            <p
                style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "132%",
                    textAlign: "left"
                }}
            >
                «Сила Капитал», передать Операторам связи («Билайн» (Публичное Акционерное
                Общество «Вымпел-Коммуникации» расположенное по адресу: РФ, 127083, г.
            </p>
            <p style={{ paddingLeft: "23pt", textIndent: "0pt", textAlign: "left" }}>
                Москва, ул. Восьмого марта, д. 10, стр. 14); «МТС» (Публичное Акционерное
            </p>
            <p
                style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                Общество «Мобильные ТелеСистемы» расположенное по адресу: РФ, 109147, г.
                Москва, ул. Марксистская, д. 4); «МегаФон» (Публичное Акционерное Общество
            </p>
            <p
                style={{
                    paddingTop: "3pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    textAlign: "left"
                }}
            >
                «МегаФон» расположенное по адресу: РФ, 127006, г. Москва, Переулок
            </p>
            <p
                style={{
                    paddingTop: "4pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "132%",
                    textAlign: "left"
                }}
            >
                Оружейный, дом 41); «Теле2» (Общество с ограниченной ответственностью «Т2
                Мобайл» расположенное по адресу: РФ, 125212, г. Москва, Ленинградское ш., д.
                39А, стр. 1) и др.) сведения об абоненте (абонентские номера, сведения об
                абонентском устройстве) и даю свое согласие Операторам связи на обработку
                сведений об абоненте, указанных в договоре об оказании услуг связи,
                заключенном с таким Оператором связи, включая сведения об оказываемых мне
                услугах связи, абонентские номера, сведения об абонентском устройстве,
                другие данные, позволяющие идентифицировать абонентское устройство, сведения
                о трафике и платежах абонента (за исключением сведений, составляющих тайну
                связи) и предоставление результатов обработки ООО «Сила Капитал» с целью
                принятия ООО «Сила Капитал» решения о заключении со мной договоров и
                использовании данных в рамках заключенных договоров. Данное согласие
                действует 9 лет и может быть отозвано в любой момент при обращении в ООО
            </p>
            <p
                style={{
                    paddingTop: "1pt",
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "131%",
                    textAlign: "left"
                }}
            >
                «Сила Капитал» либо к Оператору связи посредством заявления в письменной
                форме.Настоящим также я даю свое согласие ПАО «ВымпелКом», ПАО
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                «Мобильные ТелеСистемы», ПАО «МегаФон», ООО «Т2 Мобайл» на предоставление
                ООО
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                «Сила Капитал» информации, полученной на основании исключительно
                автоматизированной обработки, для принятия последними решений в отношении
                предоставления услуг и/или заключения соответствующих договоров.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p
                style={{
                    paddingLeft: "23pt",
                    textIndent: "0pt",
                    lineHeight: "133%",
                    textAlign: "left"
                }}
            >
                В случае отзыва настоящего согласия ООО «Сила Капитал» обязан прекратить
                совершение действий по обработке персональных данных, за исключением
                действий по обработке персональных данных, обязанность по совершению которых
                возложена на ООО «Сила Капитал» законодательными и иными
                нормативнымиправовыми актами Российской Федерации, и иных случаев, когда ООО
                «Сила Капитал» вправе осуществлять обработку персональных данных без моего
                согласия на основаниях, предусмотренных федеральными законами Российской
                Федерации.
            </p>
        </main>
    )
}