import React from "react";
import "./Politic.css";

export const Politic = () => {
    return <main className="politic">
            <h1
                style={{
                    paddingTop: "3pt",
                    paddingLeft: "13pt",
                    textIndent: "0pt",
                    textAlign: "center"
                }}
            >
                ПОЛИТИКА КОНФИДЕНЦИАЛЬНОСТИ ДЛЯ МОБИЛЬНОГО ПРИЛОЖЕНИЯ
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <h1 style={{ paddingLeft: "13pt", textIndent: "0pt", textAlign: "center" }}>
                "ЖУРНАЛ ПЛЮС"
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <ol id="l1">
                <li data-list-text={1}>
                    <h1
                        style={{ paddingLeft: "41pt", textIndent: "-13pt", textAlign: "left" }}
                    >
                        ОПРЕДЕЛЕНИЕ ПОНЯТИЙ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Закон</b>" означает Федеральный закон Российской Федерации "О
                        персональных данных" со всеми изменениями и дополнениями, а также иные
                        законодательные акты Российской Федерации.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Мобильное приложение</b>" это программное обеспечение (со всеми
                        существующими дополнениями и улучшениями), предназначенное для работы на
                        смартфонах, планшетах, часах и других мобильных устройствах, и
                        разработанное для конкретной платформы (iOS, Android, Windows Phone и т.
                        д.). Для целей настоящей Политики под Мобильным приложением
                        подразумевается следующее программное обеспечение: ЖУРНАЛ ПЛЮС.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Персональные данные</b>" означает совокупность личных данных и/или
                        неперсонифицированной информации о Пользователе, предоставляемую им
                        самим Правообладателю и/или автоматически собираемую Правообладателем
                        и/или третьими лицами.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Политика</b>" означает настоящею Политику конфиденциальности
                        мобильного приложения (со всеми существующими дополнениями и
                        изменениями).
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Пользователь</b>" означает юридическое или физическое лицо, которое
                        загрузило Мобильное приложение на смартфон, планшет, часы или любое
                        другое мобильное устройство и/или осуществило активацию такого
                        Мобильного приложения на одном из указанных устройств.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Пользовательское соглашение</b>" означает соглашение, заключаемое
                        между Правообладателем и Пользователем в отношении порядка, правил и
                        особенностей использования Пользователем Мобильного приложения.
                        Пользователь присоединяется к такому соглашению и не имеет права вносить
                        и/или требовать внесения в него каких-либо изменений или дополнений.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        "<b>Правообладатель</b>" означает следующее лицо, которому принадлежат
                        исключительные права владения Мобильным приложением
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        className="s1"
                        style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}
                    >
                        ООО "Сила Капитал" 156003, Костромская область, Г.О. ГОРОД КОСТРОМА, УЛ
                        КОММУНАРОВ, Д. 40, ЭТАЖ 3, ПОМЕЩ./К. 2/50,
                    </p>
                    <p style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}>
                        <a href="mailto:plusjornal@gmail.com" className="a" target="_blank">
                            контактные данные:{" "}
                        </a>
                        <a href="mailto:plusjornal@gmail.com" target="_blank">
                            plusjornal@gmail.com
                        </a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={2}>
                    <h1
                        style={{ paddingLeft: "41pt", textIndent: "-13pt", textAlign: "left" }}
                    >
                        ОТНОШЕНИЯ, НА КОТОРЫЕ РАСПРОСТРАНЯЕТСЯ ПОЛИТИКА
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Общие положения
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Данная Политика используется и применима исключительно к Персональным
                        данным, получаемым от Пользователя в связи с использованием им
                        Мобильного приложения. Положения данной Политики направлены на:
                    </p>
                    <ol id="l2">
                        <li data-list-text="(1)">
                            <p
                                style={{
                                    paddingTop: "3pt",
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                определение видов и типов получаемых Персональных данных,
                                направлений и целей использования (обработки) Персональных данных, а
                                также источников получения таких Персональных данных; и
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(2)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                определение прав Пользователя в отношении защиты конфиденциальности
                                передаваемых им Персональных данных; и
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(3)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                определение лиц, ответственных за обработку и хранение Персональных
                                данных, а также третьих лиц, которым такие данные раскрываются
                                (полностью или частично).
                            </p>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правила настоящей Политики не применяются в случае обработки третьими
                        лицами Персональных данных, которые добровольно предоставляются
                        Пользователем.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Посредством установки и/или активации Мобильного приложения на
                        смартфоне, планшете, часах или другом мобильном устройстве Пользователь
                        соглашается с условиями данной Политики и дает свое согласие
                        Правообладателю на сбор, обработку, удержание и хранение Персональных
                        данных в порядке и на условиях, предусмотренных настоящей Политикой.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Если Пользователь не согласен с условиями Политики и/или отдельные
                        условия Политики ему не понятны, в таком случае Пользователь обязан
                        немедленно прекратить использование Мобильного приложения.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Права пользователя по защите персональных данных
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        В связи с предоставлением Персональных данных Пользователь автоматически
                        получает следующие права:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l3">
                        <li data-list-text="(1)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                получать данные, касающиеся их обработки (основания и цели такой
                                обработки, применяемые способы обработки, сведения о лицах, которые
                                имеют доступ к ним или которым они могут быть раскрыты на основании
                                договора или Закона).
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(2)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                получать данные о месте нахождения и идентификационных данных лиц,
                                совершающих обработку Персональных данных.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(3)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                получать данные о сроках хранения Персональных данных.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(4)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                получать данные об осуществленной или о предполагаемой
                                трансграничной передаче Персональных данных.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(5)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                обжаловать действия или бездействие Правообладателя в уполномоченный
                                орган по защите прав субъектов персональных данных или в судебном
                                порядке.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(6)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                получать возмещение убытков и/или компенсацию морального вреда в
                                судебном порядке в следствие допущенных Правообладателем и/или
                                третьими лицами нарушений прав Пользователя на охрану и защиту его
                                Персональных данных.
                            </p>
                        </li>
                    </ol>
                    <h1
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "28pt",
                            textIndent: "0pt",
                            textAlign: "left"
                        }}
                    >
                        (7{" "}
                        <span className="p">
          реализовывать иные права в области защиты персональных данных,
          предусмотренные Законом или положениями данной Политики.
        </span>
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={3}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "justify"
                        }}
                    >
                        ПЕРЕЧЕНЬ СОБИРАЕМЫХ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Неперсонифицированная информация о пользователях
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        В связи с использованием Мобильного приложения Правообладатель может
                        автоматически собирать и обрабатывать следующею неперсонифицированную
                        информацию о Пользователе:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l4">
                        <li data-list-text="(1)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                информацию о трафике, возможном количестве совершенных кликов, логи
                                и другие данные.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(2)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                информацию об устройстве (идентификационный номер, сеть мобильного
                                оператора), с которого выполняется вход, операционная система,
                                платформа, тип браузера и другая информация о браузере, IP адрес.
                            </p>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Личные данные о пользователях
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь предоставляет Правообладателю о себе следующие личные
                        данные:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l5">
                        <li data-list-text="(1)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                номер мобильного телефона.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(2)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                пол Пользователя.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(3)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                данные, которые содержатся в личном кабинете (профиле) Пользователя,
                                все внутренние переписки Пользователя (если есть), а также иная
                                активность личного кабинета (профиля) Пользователя.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(4)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                данные о всех совершенных Пользователем публикациях в Мобильном
                                приложении, включая, однако не ограничиваясь, комментарии,
                                выставление оценок, отзывы, публикация отчетов, видео и фотографии,
                                проставление лайков, рейтинги и/или какие-либо другие формы
                                активности, доступные Пользователю в Мобильном приложении, и/или
                                создаваемом контенте.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(5)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                следующие данные:
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                            <p
                                className="s1"
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "left"
                                }}
                            >
                                - город проживания
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(6)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                данные и информация, получаемая в результате объединения тех или
                                иных Персональных данных конкретного Пользователя, а также данные и
                                информация, получаемые данные о Пользователе, получаемые от третьих
                                лиц (партнеров, маркетологов, исследователей).
                            </p>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь является единственным ответственным лицом за полноту
                        предоставляемых личных (персональных) данных и обязан осуществлять
                    </p>
                    <p
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        своевременное их изменение (обновление, проверку, корректировку) на
                        регулярной основе.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель исходит из того, что все предоставленные Пользователем
                        личные (персональные) данные являются достоверными, и что Пользователь
                        поддерживает такую информацию в актуальном состоянии.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={4}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "justify"
                        }}
                    >
                        ЦЕЛИ СБОРА И ОБРАБОТКИ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Определение целей обработки
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Сбор и обработка Персональных данных осуществляется в следующих целях:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <ol id="l6">
                        <li data-list-text="(1)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для анализа поведения Пользователя, а также выявления предпочтений
                                Пользователя к определенному виду контента.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(2)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для оперативной и корректной работы Мобильного приложения, улучшения
                                функционирования работы Мобильного приложения, улучшения контента
                                Мобильного приложения, улучшения внутренней архитектуры и
                                функциональности Мобильного приложения.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(3)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                для идентификации Пользователя.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(4)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для предоставления персонализированных рекламных и маркетинговых
                                материалов.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(5)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для направления персонализированных рекламных и маркетинговых
                                материалов на указанный электронный адрес и/или мобильный телефон
                                Пользователя.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(6)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                для соблюдения требований Закона.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(7)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для технической поддержки Мобильного приложения, выявления проблем в
                                его работе и их устранение.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(8)">
                            <p
                                style={{
                                    paddingLeft: "46pt",
                                    textIndent: "-18pt",
                                    textAlign: "justify"
                                }}
                            >
                                для поддержания связи с Пользователем (коммуникация).
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(9)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для выполнения иных обязательств Правообладателя, которые возникли
                                перед Пользователем.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(10)">
                            <p
                                style={{
                                    paddingLeft: "53pt",
                                    textIndent: "-24pt",
                                    textAlign: "justify"
                                }}
                            >
                                для проведения статистических исследований.
                            </p>
                            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                                <br />
                            </p>
                        </li>
                        <li data-list-text="(11)">
                            <p
                                style={{
                                    paddingLeft: "28pt",
                                    textIndent: "0pt",
                                    textAlign: "justify"
                                }}
                            >
                                для любых других целей, при условии получения отдельного согласия от
                                Пользователя.
                            </p>
                        </li>
                    </ol>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Обработка Персональных данных осуществляется на основе принципов:{" "}
                        <b>(1) </b>законности целей и способов обработки; и <b>(2)</b>
                        добросовестности; и <b>(3) </b>соответствия целей обработки Персональных
                        данных целям, заранее определенным и заявленным при сборе таких
                        Персональных данных; и <b>(4)</b>
                    </p>
                    <p
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        соответствия объема и характера обрабатываемых Персональных данных
                        заявленным целям их обработки.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Условия обработки персональных данных
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Обработка Персональных данных проводится в случаях: <b>(1) </b>получения
                        согласия от Пользователя; или <b>(2)</b>достижения Правообладателем
                        целей, предусмотренных международным договором или Законом; или{" "}
                        <b>(3) </b>предоставления Пользователем своих Персональных данных
                        неограниченному кругу лицу; или <b>(4) </b>выполнения иных обязательств
                        Правообладателя перед Пользователем, включая, однако не ограничиваясь,
                        предоставление определенного контента Пользователю; или <b>(5) </b>
                        спасения жизни или здоровья Пользователя, когда согласие на обработку
                        его Персональных данных не удается получить заблаговременно.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        В случае обезличивания Персональных данных, что не позволяет прямо или
                        опосредованно определить Пользователя, последующее использование и
                        раскрытие таких данных третьим лицам допускается и в отношении их более
                        не применяются правила данной Политики.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель принимает все возможные меры для защиты
                        конфиденциальности полученных Персональных данных, за исключением
                        случаев, когда Пользователь сделал такие данные общедоступными.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Обработка Персональных данных осуществляется с использованием средств
                        автоматизации и без использования таких средств автоматизации.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={5}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "left"
                        }}
                    >
                        ДОСТУП ТРЕТЬИХ ЛИЦ К ПЕРСОНАЛЬНЫМ ДАННЫМ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Использование ремаркетинговых сервисов
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель использует ремаркетинг для рекламы Пользователю контента
                        Мобильного приложения на других сайтах, посещаемых Пользователем.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Ремаркетинговые сервисы Правообладателю предоставляются посредством
                        следующей платформы: App Metrica. Указанный провайдер собирает и
                        обрабатывает неперсонифицированные данные, которые напрямую не позволяют
                        установить или идентифицировать Пользователя. Собираемая информация, как
                        правило, может включать <b>(1) </b>контент, который просматривал
                        Пользователь, <b>(2) </b>дату и время, когда Пользователь просматривал
                        контент, <b>(3) </b>данные о геолокации. Сбор и обработка такой
                        неперсонифицированной информации позволяет предоставлять Пользователю
                        более таргетинговый рекламный или маркетинговый контент.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Посредством установки Мобильного приложения Пользователь соглашается с
                        политикой конфиденциальности (Privacy Policy) и Пользовательским
                        соглашением (Terms of Use) указанного провайдера ремаркетинговых услуг,
                        а также с автоматической установкой на устройство Пользователя
                        соответствующих Файлов куки.
                    </p>
                    <p
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        Пользователь имеет право в любой момент отказаться от такой рекламы
                        посредством изменений соответствующих настроек браузера и устройства, с
                        которого совершается вход в Мобильное приложение.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Использование аналитических платформ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель использует аналитическую платформу Firebase для{" "}
                        <b>(1) </b>отслеживания частоты посещаемости сайта Пользователями; и{" "}
                        <b>(2) </b>отслеживания способов использования Пользователем Мобильного
                        приложения и/или его контента; и <b>(3) </b>выявления типа и вида
                        контента, который является популярными среди Пользователей; и <b>(4)</b>
                        определения места нахождения Пользователя. Пользователь также дает свое
                        согласие Правообладателю на использование им информации, полученной о
                        Пользователе от Firebase.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Для указанных целей аналитическая платформа Firebase может собирать
                        данные об IP адресе, геолокации, поведении Пользователя, а также его
                        предпочтениях и интересе в отношении определенного контента.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Аналитическая платформа Firebase получает доступ к Персональным данным с
                        тем, чтоб предоставить Правообладателю понимание того насколько
                        эффективно работает его Мобильное приложение, какой именно контент
                        является популярным, насколько эффективно размещение в нем той или иной
                        рекламы, а также для целей разработки и/или улучшения существующей
                        маркетинговой стратегии Правообладателя.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Посредством установки Мобильного приложения Пользователь соглашается с
                        политикой конфиденциальности (Privacy Policy) Firebase, а также с
                        автоматической установкой на устройство Пользователя соответствующих
                        Файлов куки.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Раскрытие персональных данных третьим лицам
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель имеет право раскрывать Персональные данные <b>(1) </b>
                        своим аффилированным лицам, филиалам и представительствам, открытым как
                        на территории Российской Федерации, так и на территории других
                        государств; <b>(2) </b>правопреемникам Правообладателя, которые возникли
                        в результате его ликвидации, реорганизации или банкротства, и которые
                        получили исключительные права владения Мобильным приложением;{" "}
                        <b>(3) </b>третьим лицам исключительно для целей оказания получения
                        Пользователем определенного контента или доступа к нему; <b>(4) </b>
                        третьим лицам, когда Пользователем было дано согласие на раскрытие,
                        передачу или обработку своих Персональных данных, а также в иных
                        случаях, прямо предусмотренных Законом или данной Политикой.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель раскрывает Персональные данные только в том случае, если
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        (1){" "}
                        <span className="p">
          уверен, что третьи лица будут соблюдать условия данной Политики и
          предпринимать такие же меры по защите конфиденциальности Персональных
          данных, которые предпринимает сам Правообладатель, и{" "}
        </span>
                        (2){" "}
                        <span className="p">
          согласие на такое раскрытие было предварительно выражено Пользователем
          и/или допускается на основании Закона.
        </span>
                    </h1>
                    <h1
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        Реклама от третьих лиц
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Контент Мобильного приложения может содержать рекламные баннеры и/или
                        ссылки на сайты третьих лиц. Использование Пользователем таких сайтов
                        (путем перехода по ссылке или любым другим способом) может повлечь за
                        собой сбор, обработку и использование Персональных данных, а также
                        возможную автоматическую передачу Файлов куки на устройство
                        Пользователя, с которого совершается переход на сайт третьих лиц.
                        Правообладатель не несет какой-либо ответственности за способы, методы и
                        порядок обработки Персональных данных сайтами третьих лиц. В следствие
                        чего Правообладатель также не является ответственным лицом в случае
                        раскрытия Персональных данных неограниченному кругу лиц в связи с
                        использованием Пользователем таких сайтов.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель настоятельно рекомендует каждому Пользователю детально
                        ознакомиться с политиками защиты персональных данных используемых
                        сайтов.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Рассылка рекламных материалов пользователям
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь автоматически с установкой Мобильного приложения на
                        устройство соглашается с правом третьих лиц отправлять на
                        предоставленный электронный адрес и/или мобильный телефон
                        персонализированные рекламные и маркетинговые материалы.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь имеет право в любой момент отказаться от получения таких
                        рекламных и маркетинговых материалов посредством выполнения следующих
                        действий:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}>
                        <a href="mailto:plusjornal@gmail.com" className="a" target="_blank">
                            Направить соответствующее письмо с отказом от получения рекламных
                            материалов по адресу{" "}
                        </a>
                        <a href="mailto:plusjornal@gmail.com" target="_blank">
                            plusjornal@gmail.com
                        </a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={6}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "left"
                        }}
                    >
                        РАЗМЕЩЕНИЕ РЕКЛАМЫ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Реклама в мобильном приложении
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель вместе с контентом размещает в Мобильном приложении
                        различные рекламные и маркетинговые материалы с учетом выявленных
                        предпочтений Пользователя к тому или иному контенту. Размещение рекламы
                        в Мобильном приложении предполагает установку на устройстве
                        Правообладателя определенных Файлов куки.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Рассылка рекламных материалов
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь автоматически с установкой Мобильного приложения на
                        устройство соглашается с правом Правообладателя отправлять на
                        предоставленный электронный адрес и/или мобильный телефон
                        персонализированные рекламные и маркетинговые материалы.
                    </p>
                    <p
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        Пользователь имеет право в любой момент отказаться от получения таких
                        рекламных и маркетинговых материалов посредством выполнения следующих
                        действий:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}>
                        <a href="mailto:plusjornal@gmail.com" className="a" target="_blank">
                            Направить соответствующее письмо с отказом от получения рекламных
                            материалов по адресу{" "}
                        </a>
                        <a href="mailto:plusjornal@gmail.com" target="_blank">
                            plusjornal@gmail.com
                        </a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Обращаем внимание, что в случае отказа от получения рекламных и
                        маркетинговых материалов, Пользователь может продолжать получать от
                        Правообладателя какие-либо уведомления, связанные с порядком
                        использования Мобильного приложения и/или его контента.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={7}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "left"
                        }}
                    >
                        НАПРАВЛЕНИЕ ЖАЛОБ И ЗАПРОСОВ ПРАВООБЛАДАТЕЛЮ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Требование о прекращении обработки персональных данных
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Каждый Пользователь имеет право выразить свое возражение Правообладателю
                        против обработки и/или хранения его Персональных данных. Такое
                        возражение может быть выражено следующим образом:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        className="s1"
                        style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}
                    >
                        Запрос должен быть отправлен Правообладателю по адресу
                    </p>
                    <p style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}>
                        <a href="mailto:plusjornal@gmail.com">plusjornal@gmail.com</a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Запрос на получение информации о персональных данных
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Если у Пользователя возникают вопросы, связанные с порядком применения
                        или использования настоящий Политики, порядком и/или способом обработки
                        Персональных данных, Пользователь может задать такой вопрос следующим
                        образом:
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        className="s1"
                        style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}
                    >
                        Вопрос должен быть отправлен Правообладателю по адресу
                    </p>
                    <p style={{ paddingLeft: "28pt", textIndent: "0pt", textAlign: "left" }}>
                        <a href="mailto:plusjornal@gmail.com">plusjornal@gmail.com</a>
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <h1
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Изменение (обновление, дополнение, корректировка) или удалении
                        персональных данных
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Пользователь имеет право в любой момент самостоятельно изменить или
                        удалить свои Персональные данные, за исключением случаев, когда такое
                        изменение или удаление может привести <b>(1) </b>к нарушению правил
                        настоящей Политики; или <b>(2) </b>к нарушению Закона; <b>(3) </b>
                        характер таких Персональных данных является доказательством в каком-либо
                        судебном процессе, возникшем между Правообладателем и Пользователем. Для
                        этого Пользователю требуется удалить свой личный аккаунт (профиль) в
                        Мобильном приложении.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель имеет право в любой момент удалить личный
                        аккаунт/профиль Пользователя, а также все Персональные данные о
                        Пользователе, если он нарушил условия данной Политики и/или
                        Пользовательского соглашения.
                    </p>
                    <p
                        style={{
                            paddingTop: "3pt",
                            paddingLeft: "5pt",
                            textIndent: "0pt",
                            textAlign: "justify"
                        }}
                    >
                        В случае удаления Персональных данных о Пользователе, автоматическому
                        удалению подлежат также все совершенные таким Пользователем публикации
                        (комментарии, выставление оценок, отзывы, публикация отчетов, видео и
                        фотографии, проставление лайков, рейтинги) и/или какие-либо другие формы
                        активности, доступные Пользователю в Мобильном приложении.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={8}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "left"
                        }}
                    >
                        СРОКИ И ПОРЯДОК ХРАНЕНИЯ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Хранение осуществляется самостоятельно Правообладателем.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Хранение осуществляется в течение всего срока использования
                        Пользователем данного Мобильного приложения.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель обязуется немедленно после прекращения использования
                        Пользователем Мобильного приложения уничтожить или же обезличить его
                        Персональные данные.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={9}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "41pt",
                            textIndent: "-13pt",
                            textAlign: "left"
                        }}
                    >
                        ДОСТУП НЕСОВЕРШЕННОЛЕТНИХ К МОБИЛЬНОМУ ПРИЛОЖЕНИЮ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Мобильное приложение может быть использовано лицами в возрасте до 18
                        лет.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Мобильное приложение собирает личные данные о Пользователях, потому
                        использование настоящего приложения несовершеннолетними Пользователями
                        допускается только при условии получения предварительного согласия от
                        законного представителя (опекуна) на обработку Персональных данных.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Если несовершеннолетний Пользователь не может предоставить согласие на
                        обработку его Персональных данных от законного представителя (опекуна),
                        в таком случае такой Пользователь обязан немедленно прекратить
                        использование Мобильного приложения.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={10}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "48pt",
                            textIndent: "-20pt",
                            textAlign: "left"
                        }}
                    >
                        ПОРЯДОК ЗАЩИТЫ ПЕРСОНАЛЬНЫХ ДАННЫХ
                    </h1>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Защита конфиденциальности Персональных данных является первостепенной и
                        важной задачей для Правообладателя. Правообладатель придерживается всех
                        требуемых международных стандартов, правил и рекомендаций по защите
                        Персональных данных.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                    <p
                        style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}
                    >
                        Правообладатель внедрил ряд технических и организационных методов,
                        направленных на защиту Персональных данных от разглашения или
                        несанкционированного доступа к ним третьих лиц.
                    </p>
                    <p style={{ textIndent: "0pt", textAlign: "left" }}>
                        <br />
                    </p>
                </li>
                <li data-list-text={11}>
                    <h1
                        style={{
                            paddingTop: "9pt",
                            paddingLeft: "48pt",
                            textIndent: "-20pt",
                            textAlign: "left"
                        }}
                    >
                        ЗАКЛЮЧИТЕЛЬНЫЕ ПОЛОЖЕНИЯ
                    </h1>
                </li>
            </ol>
            <h1
                style={{
                    paddingTop: "3pt",
                    paddingLeft: "5pt",
                    textIndent: "0pt",
                    textAlign: "left"
                }}
            >
                Доступность текста политики для ознакомления
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                Данная редакция Политики действует от 17 октября 2023 годa.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "left" }}>
                Изменение и дополнение политики
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Настоящая Политика может быть изменена время от времени. Правообладатель не
                несет какой-либо ответственности перед Пользователем за изменение условий
                данной Политики без разрешения и/или согласия Пользователя.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Пользователь сам обязуется на регулярной основе проверять положения данной
                Политики на предмет ее возможного изменения или дополнения.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Применимое законодательство
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Настоящая Политика разработана в соответствие с действующим
                законодательством о защите персональных данных Российской Федерации, в
                частности, с нормами Федерального закона от 27 июля 2006 года № 152-ФЗ "О
                персональных данных" (со всеми дополнениями и изменениями), Федерального
                закона от 21 июля 2014 года № 242-ФЗ "О внесении изменений в отдельные
                законодательные акты Российской Федерации в части уточнения порядка
                обработки персональных данных в информационно-телекоммуникационных сетях"
                (со всеми дополнениями и изменениями).
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Риск разглашения
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Вне зависимости от предпринимаемых Правообладателем мер защиты
                конфиденциальности получаемых персональных данных, Пользователь настоящим
                считается должным образом ознакомлен с тем, что любая передача Персональных
                данных в сети Интернет не может быть гарантировано безопасной, а потому
                Пользователь осуществляет такую передачу на свой собственный риск.
            </p>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <h1 style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                Публичная информация
            </h1>
            <p style={{ textIndent: "0pt", textAlign: "left" }}>
                <br />
            </p>
            <p style={{ paddingLeft: "5pt", textIndent: "0pt", textAlign: "justify" }}>
                В рамках Мобильного приложения Пользователь имеет право осуществлять
                публикации и размещать любой контент по своему собственному усмотрению и в
                любой из доступных форм (фото, видео, комментарий, статья, оценка, блог и
                т.п.). Такие публикации и контент являются общедоступными для других
                пользователей Мобильного приложения, в связи с чем Правообладатель не берет
                на себе какие-либо обязательства по защите Персональных данных, которые
                могут быть обнародованы или опубликованы в рамках такой публикации и/или
                контента.
            </p>
    </main>
}