import React from "react";
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import {Home} from "./pages/Home/Home";
import {Politic} from "./pages/Politic/Politic";
import {Personal} from "./pages/Personal/Personal";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "politic",
    element: <Politic/>,
  },
  {
    path: "personal",
    element: <Personal/>,
  },
  {
    path: "*",
    element: <Home />,
  }
]);

function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
